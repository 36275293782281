import { breakpointsConfig } from '~/utils/css/breakpoints'
const isTablet = useMediaQuerySSR(`(min-width: ${breakpointsConfig.tablet}px)`)


type ImageType = {
    smallAsset?: string
    largeAsset?: string
    altTextSmall?: string
    altTextLarge?: string
}


export function useImage(image: ImageType) {
    const imageMobile = computed(() => {
        if (image.smallAsset)
            return image.smallAsset
    
        return image.largeAsset
    })

    const imageAlt = computed(() => {
        if (!isTablet.value && image.altTextSmall)
            return image.altTextSmall
    
        if (isTablet.value && image.altTextLarge)
            return image.altTextLarge
    
        return image.altTextSmall
    })
    return {
       imageMobile,
       imageAlt,
      }
}
